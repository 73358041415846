import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import Accordion from "react-bootstrap/Accordion";
import CustomAccordionItem from "./CustomAccordionItem";
import "./CustomAccordion.css";
import { isConditionMetForApproveIcon, sortByAwaitingFirst } from "../../helper/Helper";

function CustomAccordion({
    mainData,
    index,
    data,
    handleSubmitForApprovalClick,
    handleViewSelectSkill,
    showActionColumn,
    setAddress,
    address,
    setChekedLernerIds,
    chekedLernerIds,
    getMySkillJourneyData,
    handleClearAll,
    setSelectedSkills,
    selectedSkills,
    updateFilterUi
}) {
    const [accordionData, setAccordionData] = useState();
    const [activeKeys, setActiveKeys] = useState([0]);
    const keys = [];

    useEffect(() => {
        const transformedData = data.map((item) => {
            const skills = item.skills.map((skill) => {
                const isDisabled = data.some(
                    (otherItem) =>
                        otherItem.id !== item.id &&
                        otherItem.skills.some(
                            (otherSkill) =>
                                otherSkill.skillName === skill.skillName &&
                                otherSkill.history.some(
                                    (history) => history.status === "Awaiting Approval"
                                )
                        )
                );

                const history = skill.history.map((h) => {
                    return {
                        ...h,
                        showApproveIcon: isConditionMetForApproveIcon(h, mainData)
                    };
                });

                return {
                    ...skill,
                    history: sortByAwaitingFirst(history),
                    skillName: skill.skillName,
                    disabled: isDisabled
                };
            });

            return {
                ...item,
                id: item.id,
                title: item.title,
                skills: skills
            };
        });

        setAccordionData(transformedData);
        data &&
            data.map((elm, i) => {
                keys.push(i);
            });
    }, [data]);

    const handleAccordionToggle = (key) => {
        setActiveKeys((prevActiveKeys) => {
            if (prevActiveKeys.includes(key)) {
                // If the key is already open, close it
                return prevActiveKeys.filter((activeKey) => activeKey !== key);
            } else {
                // Otherwise, open it
                return [...prevActiveKeys, key];
            }
        });
    };

    return (
        <Accordion
            key={index}
            activeKey={activeKeys}
            onSelect={(selectedKey) => handleAccordionToggle(Number(selectedKey))}
            className="custom-accordion">
            {!isEmpty(accordionData) &&
                accordionData.map((elm, i) => (
                    <Accordion.Item key={`${i}Item`} eventKey={i}>
                        <Accordion.Header>{elm.title}</Accordion.Header>
                        <Accordion.Body key={`${i}body`}>
                            <CustomAccordionItem
                                mainData={mainData}
                                data={elm.skills}
                                handleSFAClick={handleSubmitForApprovalClick}
                                handleViewSelectSkill={handleViewSelectSkill}
                                showActionColumn={showActionColumn}
                                setAddress={setAddress}
                                address={address}
                                setChekedLernerIds={setChekedLernerIds}
                                chekedLernerIds={chekedLernerIds}
                                getMySkillJourneyData={getMySkillJourneyData}
                                handleClearAll={handleClearAll}
                                setSelectedSkills={setSelectedSkills}
                                selectedSkills={selectedSkills}
                                index={i}
                                updateFilterUi={updateFilterUi}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
        </Accordion>
    );
}

export default CustomAccordion;
