/* eslint-disable linebreak-style */
import axios from "axios";
import { getToken, setToken, clearLocalStorage } from "./Auth";
import { base_url } from "../AppConfig";
import {apis} from "../services/Api";
import ErrorModal from "./errorModal"
import { useState, useEffect } from "react";

export function ErrorInterceptor() {
    const [error, setError] = useState("");
    const [showModal,setShowModal]= useState(false)

    const closeModal = () => {
        setShowModal(false) ;
        clearLocalStorage();
        localStorage.setItem("priviousUrl", window.location.pathname + window.location.hash);
        window.location.href = "/login?r=t";
    }
    useEffect(() => {
        const interceptor = axios.interceptors.response.use(null, async (err) => {
            const originalConfig = err.config;
            if (!err.response) {
                // network error
                console.error(err);
                return;
            }
            if (originalConfig.url !== `${base_url}/oauth/token/refresh`) {
                // Access Token was expired
                if ((err?.response?.status === 400 && err?.response?.data?.source?.info === "Invalid token") || err?.response?.status === 401) {
                    try {
                        if( err?.response?.status === 401){
                            setError("Your session is expired. Click ok to login again.");
                            setShowModal(true)
                        } else{
                            const refresh_token = (getToken()).refresh_token;
                            const result = await apis.refreshToken(refresh_token);
                            setToken(result);
                            window.location.href = "/";
                        }
                    } catch (_error) {
                        if( err?.response?.status === 401){
                            setError("Your session is expired. Click ok to login again.");
                        } else {
                            setError("Your session is expired. Click ok to login again.");
                        }
                        setShowModal(true)
                    }
                }
            } else {
                clearLocalStorage()
                window.location.href = "/login";
            }
            return err.response;
        }
        );
        return () => {
            axios.interceptors.response.eject(interceptor);
        };
    }, []);
    return (
        <>
            {showModal && (
                <ErrorModal
                    error = {error}
                    showModal ={showModal}
                    closeModal = {closeModal}
                />
            )}
        </>
    );
}
