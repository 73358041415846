import React, { useEffect, useRef, useState } from "react";
import { getToken } from "../../services/Auth";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { apis } from "../../services/Api";

function CoursePlayer() {
    const [errorMsg, setErrorMsg] = useState(false)
    let navigate = useNavigate();
    const started_time = Math.floor(Date.now() / 1000);
    let id = useParams();
    let location = useLocation();
    let { state } = location;
    let hostname = "learningmanager.adobe.com";
    let trapfocus = true;
    let reset_attempt = false;
    let cid = "";
    let mid = "";
    let instance_id = "";
    let attemptEndCriteria = "NONE";
    let streamingStarted = false;
    let crumbs1 = state?.crumbs || [];
    let crumbs = crumbs1?.length > 0 && [...crumbs1.slice(0, -1)];
    if (location.state !== null) {
        cid = location.state.cid;
        mid = location.state.mid;
        instance_id = location.state.instanceId;
        attemptEndCriteria = location.state.attemptEndCriteria;
    }

    const newState = {
        cid,
        mid,
        crumbs,
    };

    let iframeRef = useRef(null);
    useEffect(() => {
        const initializePlayer = async () => {
            if (location.state !== null) {
                let url = `https://learningmanager.adobe.com/app/player?hostname=${hostname}&trapfocus=${trapfocus}&reset_attempt=${reset_attempt}&access_token=${getToken().access_token}`;

                if(cid.startsWith("course:")) {
                    url += `&lo_id=${cid || id?.id}`;
                    if (mid && mid !== "") {
                        url += `&module_id=${mid}`;
                    } else {
                        const response = await apis.playerLOState(cid, instance_id);
                        mid = response?.data?.lastPlayingLoResourceId;
                        if (mid) {
                            url += `&module_id=${mid}`;
                        }
                    }
                    if (instance_id && instance_id !== "") {
                        url += `&instance_id=${instance_id}`;
                    }
                } else {
                    const response = await apis.playerLOState(cid, instance_id);
                    if(response?.data?.lastPlayingCourse && response?.data?.lastPlayingCourse != "") {
                        cid = "course:" + response.data.lastPlayingCourse;
                        let loResourceArr = JSON.parse(sessionStorage.getItem("moduleStatusArr"));
                        let matchedEntry = loResourceArr.find(item => item.id.startsWith(cid));
                        let instanceId = matchedEntry ? matchedEntry.id.split("_").slice(0, 2).join("_") : null;
                        
                        url += `&lo_id=${cid}`;
                        if (instanceId && instanceId !== "") {
                            url += `&instance_id=${instanceId}`;
                            const response = await apis.playerLOState(cid, instanceId);
                            mid = response?.data?.lastPlayingLoResourceId;
                            if (mid) {
                                url += `&module_id=${mid}`;
                            }
                        }
                    } else {
                        mid = sessionStorage.getItem("firstModuleId");
                        let [prefix, ids] = mid.split(":");
                        let firstOne = ids.split("_").slice(0, 1).join("_");
                        let firstTwo = ids.split("_").slice(0, 2).join("_");
                        cid = `${prefix}:${firstOne}`;
                        let instanceId = `${prefix}:${firstTwo}`;
                        
                        url += `&lo_id=${cid}`;
                        url += `&module_id=${mid}`;
                        url += `&instance_id=${instanceId}`;
                    }
                }

                iframeRef.current.src = url;

                const closePlayer = (event) => {
                    if (event.data === "status:close" || event.data.type === "PLAYER_CLOSED") {
                        if(attemptEndCriteria === "PLAYER_CLOSE" && cid?.trim() && mid?.trim()) {
                            apis.moduleResetPost(cid, mid, started_time);
                        }
                        navigate(location?.state?.goBackUrl, { state: newState, replace: true });
                    }

                    if(event.data.type === "STREAMING_EVENTS" && attemptEndCriteria !== "PLAYER_CLOSE" && cid?.trim() && mid?.trim()) {
                        if(!streamingStarted) apis.moduleResetPost(cid, mid, started_time);
                        streamingStarted = true;
                    }
                };

                localStorage.setItem("url", JSON.stringify(location?.state?.goBackUrl));
                window.addEventListener("message", closePlayer);

                return () => {
                    window.removeEventListener("message", closePlayer);
                };
            } else {
                setErrorMsg(true);
                navigate("/coursedetails/" + id?.id);
                // const getbackurl = JSON.parse(localStorage.getItem("url"))
                // navigate(getbackurl, { state: newState, replace: true });
            }
        };

        initializePlayer(); // Call the async function

    }, []);

    return (
        <>
            {!(errorMsg) ? <div className="coursePlayer">
                <iframe
                    ref={iframeRef}
                    scrolling="no"
                    id="pplayer_iframe"
                    name="pfplayer_frame"
                    allowFullScreen
                    title="Player"
                    style={{
                        display: "block",
                        background: "#000",
                        border: "none",
                        height: "100vh",
                        width: "100vw",
                    }}></iframe>
            </div> : <div><h1 className="my-5 m-auto col-lg-12 noData ">No course found.</h1></div>
            }
        </>
    );
}
export default CoursePlayer;