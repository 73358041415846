module.exports = {
    base_url: "https://learningmanager.adobe.com/primeapi/v2",
    signin_url: "https://learningmanager.adobe.com",
    // front_end_app_url: "http://localhost:2000",
    client_id: "0b2b9d91-64a2-4b95-9740-faaefe97cd84",
    client_secret: "91866d82-6d2f-4ff0-93ba-d318af50e68d",
    // custom_app_url: "http://localhost:3000", //"http://localhost:3001" "http://52.205.136.34:5003",
    account_id: 115478,
    // application_url:"https://ekr5lr2ngd.execute-api.us-east-1.amazonaws.com/stage"
    application_url: "https://api.dev.dxenablementhub.adobe.com/api",
    application_template_base_url:"https://api.dev.dxenablementhub.adobe.com/api",
    // application_url:"https://1crpmx8vod.execute-api.us-east-1.amazonaws.com/localdev"
    // application_url:"https://goyv4v1s86.execute-api.us-east-1.amazonaws.com/localdev2"
    show_maintenance_view: false
};
