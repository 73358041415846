/* eslint-disable linebreak-style */
import React, { useEffect, useState } from "react";

import { account_id, client_id, signin_url, show_maintenance_view } from "../../AppConfig";
import { apis } from "../../services/Api";

import { setToken } from "../../services/Auth";
import { css } from "@emotion/react";
import { ScaleLoader } from "react-spinners";
import { toQueryString } from "../../common/queryString/toQueryString";
import "./signInstyle.css";
import logo from "../../assets/images/logo.svg";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import MaintenanceView from "./MaintenanceView";
// import LoginFailed from "../LoginFailed/LoginFailed";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

function SignIn() {
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const searchText = searchParams.get("loginCode");
    const loginCode = searchText === "xnsy8n5fBZ";
    const maintenanceView = show_maintenance_view === true;
    let forceLogout = false;
    if(maintenanceView && !loginCode) {
        forceLogout = true;
    }
    localStorage.removeItem("NavMenuData");
    localStorage.removeItem("TemplateJSONData");
    localStorage.setItem("force_logout" , forceLogout);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.href);
        const queryParams = new URLSearchParams(location.search);
        const url = queryParams.get("r");
        if(url !== "t"){
            localStorage.removeItem("priviousUrl")
        }
        const code = urlParams.get("code");
        if (code) {
            apis.getToken(code)
                .then(async (result) => {
                    setToken(result);

                    validateUser();
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            // Unexpected condition: hash does not contain error or access_token parameter
            setLoading(false);
        }
    }, []);

    let validateUser = async () => {
        await apis
            .getLoginUserDetails()
            .then((e) => {
                if (e.result.isSystemUser === false) {
                    localStorage.removeItem("CPToken");
                    setLoading(false);
                    navigate("/loginfailed");
                } else {
                    setLoading(false);
                    const getPriviousUrl = localStorage.getItem("priviousUrl");
                    if(getPriviousUrl){
                        localStorage.removeItem("priviousUrl")
                        navigate(getPriviousUrl)
                    } else{
                        navigate("/");
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            }); 
    };

    const onSignIn = () => {
        let location = window.location.href;
        let queryParams = {
            client_id: `${client_id}`,
            redirect_uri: `${location}`,
            state: "prime_auth",
            scope: "learner:read,learner:write",
            response_type: "CODE"
        };

        let authorizeEndpoint = `${signin_url}/oauth/o/authorize?logoutAfterAuthorize=true&account=${account_id}&${toQueryString(
            queryParams
        )}`;
        window.location.assign(authorizeEndpoint);
    };

    return (
        <React.Fragment>
            <div className="signin-block">
                {maintenanceView && !loginCode ? (
                    <MaintenanceView />
                ) : !loading ? (
                    <div className="main-block">
                        <div className="container">
                            <div className="login-block-txt">
                                <div className="login-box">
                                    <img src={logo} className="my-5" alt="" />
                                    <h1 className="headding-title-login my-4">
                                        {t("welcome-text-5")}{" "}
                                    </h1>
                                    <div className="headding-title-list mt-2">
                                        {t("welcome-text-1")}{" "}
                                    </div>
                                    <div className="login-text mt-2 ms-auto pe-2">
                                        <p className="mt-3 mb-0 text-center">
                                            {t("welcome-text-2")}
                                        </p>
                                        <p className="text-center president-text">
                                            {t("welcome-text-3")}
                                        </p>
                                    </div>

                                    <p className="mt-3 text-center fontSize13">
                                        {t("welcome-text-4")}
                                    </p>

                                    {!loading ? (
                                        <button
                                            type="button"
                                            className="mt-3 mb-3 fill px-5"
                                            onClick={onSignIn}>
                                            {t("login-with-val", { val: "Adobe" })}
                                        </button>
                                    ) : (
                                        <button className="fill">
                                            {
                                                <ScaleLoader
                                                    color={"#ffffff"}
                                                    loading={true}
                                                    css={override}
                                                    height={10}
                                                />
                                            }
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <LoadingOverlay
                        active={true}
                        spinner
                        text="Loading ... This shouldn't take more than 5 seconds."></LoadingOverlay>
                )}
            </div>
            <footer>
                <div className="container">
                    <div className="row footer-block">
                        <div className="footer-txt col">{/* {t("powred-by-adobe")} */}</div>
                    </div>
                </div>
            </footer>
        </React.Fragment>
    );
}

export default SignIn;