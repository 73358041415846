import React from "react";
import "../features/CustomNavigation/Menu.css";
import pre_assessment from "./Pre-Assessment.jpg";
import lp from "./LP.jpg";

const SeGenAi = () => {
    return (
        <div>
            <div>
                <div className="breadCrumb">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="pl-30 mb-0">
                                <li className="breadcrumb-item b-text "><button className="link-button">Home</button><span className="separator">
                                    &gt; </span></li>
                                <li className="breadcrumb-item b-text "><span className="breadcrumbLabel">Additional Resources</span><span
                                    className="separator"> &gt; </span></li>
                                <li className="breadcrumb-item b-text active">GenAI Fundamentals</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="pl-30 py-4 container-fluid">
                    <div className="headerImage">
                        <h1>GenAI Fundamentals</h1>
                    </div>
                    <div className="horizontal-tab-block my-5">
                        <div className="mb-0">
                            <div className="mb-0">
                                <p className="head-text mt2custom">On this page, you can access the Pre-Assessment test &amp; the GenAI
                                    Fundamentals learning path.</p>
                                <p>A) If you clear the Pre-Assessment - then you will secure the completion certificate without going
                                    through the GenAI Fundamentals Learning Path. You may still enroll yourself and complete the
                                    learning path if you would like to.</p>
                                <p>B) If you don&#39;t clear the Pre-Assessment - then you must complete the entire Learning Path and
                                    post-assessment to secure your completion certificate.</p>
                                <p>C) You may skip the Pre-Assessment and go to the GenAI Fundamentals Learning Path directly. You
                                    must complete the entire Learning Path and post-assessment to secure your completion certificate.
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-sm-12 col-md-12 no-content-noborder">
                                <div className="list-view h-100">
                                    <div className="row mb-4 list-view-block cd justify-content-center justify-content-md-start mx-0">
                                        <div className="col-auto py-4 px-4 m-auto m-lg-0">
                                            <img src={pre_assessment} alt="loading" className="img-fluid d-block list-view-block-img" />
                                        </div>
                                        <div className="col-10 py-4 px-4 text-center text-md-start">
                                            <p className="head-text-1">GenAI Fundamentals: Pre-Assessment</p>
                                            <p className="sub-text">Assess your understanding of fundamentals concepts in GenAI.</p>
                                        </div>
                                        <div className="list-footer align-items-center">
                                            <div className="associated-skill">
                                                <button className="associated-skill" type="button">Associated Skills</button>
                                            </div>
                                            <img src="./DX_GenAI page_files/time.c24d6ad0e3c80a10200da8a37f0be8dc.svg" className="px-2" alt=""
                                                title="Duration" />
                                            <span className="fontSize13">15 mins </span>
                                            <button className="btn primary-btn my-2 btn-light" type="button">
                                                <a href="https://learningmanager.adobe.com/app/learner?accountId=29997#/course/12233298" target="_blank" rel="noreferrer" style={{ color: "inherit" }}>View Details</a>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="row mb-4 list-view-block cd justify-content-center justify-content-md-start mx-0">
                                        <div className="col-auto py-4 px-4 m-auto m-lg-0">
                                            <img src={lp} alt="loading" className="img-fluid d-block list-view-block-img" />
                                        </div>
                                        <div className="col-10 py-4 px-4 text-center text-md-start">
                                            <p className="head-text-1">GenAI Fundamentals</p>
                                            <p className="sub-text">A comprehensive set of introductory GenAI courses for the ACS group.</p>
                                        </div><div className="list-footer align-items-center">
                                            <div className="associated-skill">
                                                <button className="associated-skill" type="button">Associated Skills</button>
                                            </div>
                                            <img src="/static/media/time.c24d6ad0e3c80a10200da8a37f0be8dc.svg" className="px-2" alt="" title="Duration" />
                                            <span className="fontSize13">2 hrs 52 mins 59 secs </span>
                                            <button className="btn primary-btn my-2 btn-light" type="button">
                                                <a href="https://learningmanager.adobe.com/app/learner?accountId=29997#/learningProgram/141315" target="_blank" rel="noreferrer" style={{ color: "inherit" }}>View Details</a>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SeGenAi;
