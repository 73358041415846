import React from "react";
import "./CourseDetails.css";

const ErrorScreen = () => {
    return (
        <div className="error-body">
            <div className="error-container">
                <svg width="50" height="44" viewBox="0 0 72 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                        d="M34.2532 1.15592L0.797259 61.0239C0.627157 61.3282 0.539532 61.6716 0.543072 62.0202C0.546611 62.3688 0.641192 62.7103 0.817438 63.0111C0.993683 63.3118 1.24547 63.5613 1.54784 63.7347C1.85022 63.9082 2.19267 63.9996 2.54125 63.9999H69.4532C69.8018 63.9996 70.1443 63.9082 70.4466 63.7347C70.749 63.5613 71.0008 63.3118 71.177 63.0111C71.3533 62.7103 71.4479 62.3688 71.4514 62.0202C71.455 61.6716 71.3674 61.3282 71.1973 61.0239L37.7412 1.15592C37.5674 0.846218 37.3142 0.588398 37.0077 0.408963C36.7012 0.229527 36.3524 0.134949 35.9972 0.134949C35.6421 0.134949 35.2933 0.229527 34.9868 0.408963C34.6803 0.588398 34.4271 0.846218 34.2532 1.15592ZM39.9972 54.9999C39.9972 55.2651 39.8919 55.5195 39.7043 55.707C39.5168 55.8946 39.2624 55.9999 38.9972 55.9999H32.9973C32.732 55.9999 32.4777 55.8946 32.2901 55.707C32.1026 55.5195 31.9972 55.2651 31.9972 54.9999V48.9999C31.9972 48.7347 32.1026 48.4803 32.2901 48.2928C32.4777 48.1052 32.732 47.9999 32.9973 47.9999H38.9972C39.2624 47.9999 39.5168 48.1052 39.7043 48.2928C39.8919 48.4803 39.9972 48.7347 39.9972 48.9999V54.9999ZM39.9972 42.9999C39.9972 43.1312 39.9714 43.2613 39.9211 43.3826C39.8709 43.5039 39.7972 43.6142 39.7043 43.707C39.6115 43.7999 39.5012 43.8735 39.3799 43.9238C39.2586 43.974 39.1285 43.9999 38.9972 43.9999H32.9973C32.8659 43.9999 32.7359 43.974 32.6146 43.9238C32.4932 43.8735 32.383 43.7999 32.2901 43.707C32.1973 43.6142 32.1236 43.5039 32.0733 43.3826C32.0231 43.2613 31.9972 43.1312 31.9972 42.9999V18.9999C31.9972 18.8686 32.0231 18.7385 32.0733 18.6172C32.1236 18.4959 32.1973 18.3856 32.2901 18.2928C32.383 18.1999 32.4932 18.1263 32.6146 18.076C32.7359 18.0258 32.8659 17.9999 32.9973 17.9999H38.9972C39.1285 17.9999 39.2586 18.0258 39.3799 18.076C39.5012 18.1263 39.6115 18.1999 39.7043 18.2928C39.7972 18.3856 39.8709 18.4959 39.9211 18.6172C39.9714 18.7385 39.9972 18.8686 39.9972 18.9999V42.9999Z"
                        fill="#cc1b20" />
                </svg>

                <div className="error-message">
                    Module is locked. Please try again in sometime.
                </div>
            </div>
        </div>
    )
}

export default ErrorScreen