import React from "react";
import { Navigate  } from "react-router-dom";
import { isLogin } from "../../services/Auth";

function PrivateRoute({ children }) { 
    const isLoggedIn = isLogin();
    localStorage.setItem("priviousUrl", window.location.pathname + window.location.hash);
    return isLoggedIn ? <>{children}</> : <Navigate to='/login?r=t' /> 
            
}

export { PrivateRoute };
